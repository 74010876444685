<template>
  <div class="">
    <div class="my-5">
      <vue-good-table
        :columns="columns"
        :rows="rows"
        ref="table"
        @on-cell-click="onCellClick"
        :sort-options="{
          enabled: true,
          initialSortBy: {field: 'name', type: 'asc'}
        }"
        :pagination-options="{
          enabled: true,
          mode: 'records',
          perPage: 10,
          perPageDropdownEnabled: true,
          perPageDropdown: [10, 20],
          nextLabel: '下一頁',
          prevLabel: '上一頁',
          rowsPerPageLabel: 'Rows per page',
          allLabel: '全部',
        }"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'btn'">
            <button type="button" class="btn btn-main btn-sm mb-1" style="white-space:nowrap;" @click.prevent="">編輯課程</button>
            <button type="button" class="btn btn-main btn-sm mb-1" style="white-space:nowrap;" @click.prevent="">學生管理</button>
            <button type="button" class="btn btn-danger btn-sm"
              style="white-space:nowrap;"
            >刪除</button>
          </span>
          <span v-else-if="props.column.field == 'prevImageUrl'">
            <img :src="props.row.prevImageUrl" alt="" width="180">
            <!-- <p>{{ props.row.id }}</p> -->
          </span>
          <span v-else-if="props.column.field == 'ena'">
            <input type="checkbox" name="ena" id="ena" v-model="props.row.ena">
            <!-- <span v-if="!props.row.ena"><span class="badge rounded-pill bg-success">Success</span></span> -->
          </span>
          <span v-else-if="props.column.field == 'name'">
            <span>{{ props.row.name }}</span>
            <span v-if="!props.row.reviewed"><br><span class="badge rounded-pill bg-info" style="padding: .15rem .6rem;">未審核</span></span>
          </span>
          <span v-else>
            {{props.formattedRow[props.column.field]}}
          </span>
        </template>
      </vue-good-table>
    </div>
    <Meta :title ="metaTitle" />
  </div>
</template>

<script>
/* global $ */
import { mapActions, mapGetters } from 'vuex';
import { VueGoodTable } from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css';

const Swal = require('sweetalert2');

export default {
  name: 'Teacher__Course',
  components: {
    VueGoodTable,
  },
  data() {
    return {
      page: 1,
      data: [],
      noData: false,
      tempCourse: {},
      is_enabled: true,
      createModalRole: '系統管理員',
      fileUploading: '',
      teacherList: [],
      filterTeacherArr: [],
      metaTitle: '課程列表 / 課程 / 管理 - 元學堂',
      newUser: {
        userName: '',
        avatar: '',
        email: '',
        account: '',
        role: '',
      },
      columns: [
        {
          label: '啟用',
          field: 'ena',
        },
        {
          label: '課程名稱',
          field: 'name',
          filterOptions: {
          styleClass: 'class1', // class to be added to the parent th element
          enabled: true, // enable filter for this column
          placeholder: '課程名稱', // placeholder for filter input
          filterValue: '', // initial populated value for this filter
          trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: '縮圖',
          field: 'prevImageUrl',
        },
        {
          label: '價格',
          field: 'price',
        },
        {
          label: '學生數',
          field: 'numberOfPeople',
        },
        {
          label: '單元數',
          field: 'numberOfCourse',
        },
        {
          label: '作者名稱',
          field: 'authorName',
          filterOptions: {
          styleClass: 'class1', // class to be added to the parent th element
          enabled: true, // enable filter for this column
          placeholder: '作者名稱', // placeholder for filter input
          filterValue: '', // initial populated value for this filter
          trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: '創建者',
          field: 'authorId',
          filterOptions: {
          styleClass: 'class1', // class to be added to the parent th element
          enabled: true, // enable filter for this column
          filterDropdownItems: ['DrAdmin', 'Angela', 'Shelly', 'KellyK', 'awei0905@gmail.com'], // dropdown (with selected values) instead of text input
          placeholder: ''
          },
        },
        {
          label: '創建時間',
          field: 'publishTime',
        },
        {
          label: '動作',
          field: 'btn',
        },
      ],
      rows: [
        {
          id: 0, ena: true, name: '課程1', prevImageUrl: 'https://img2.metalearning.com.tw/MO12104_s.jpg', price: 'Lorem', numberOfPeople: 20, numberOfCourse: 6, authorName: 'Luis', authorId: 'a37ba9e5-9b4a-45fb-94e9-299c6e0891c7', publishTime: '2022-05-13T18:35:17',
        },
        {
          id: 0, ena: true, name: '課程1', prevImageUrl: 'https://img2.metalearning.com.tw/MO12104_s.jpg', price: 'Lorem', numberOfPeople: 20, numberOfCourse: 6, authorName: 'Luis', authorId: 'a37ba9e5-9b4a-45fb-94e9-299c6e0891c7', publishTime: '2022-05-13T18:35:17',
        },
      ],
    };
  },
  metaInfo: {
    title: "課程列表 / 課程 / 管理 - 元學堂",
    meta: [
      { name: 'description', content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。' },
      {
        property: 'og:title',
        content: '課程列表 / 課程 / 管理 - 元學堂',
      },
      {
        property: 'og:description',
        content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。',
      },
    ]
  },
  methods: {
    getTeacherList() {
      const vm = this;
      this.updateLoading(true);
      this.$http.get(`${this.$API_PATH}/Admin/TeacherList`, {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        vm.teacherList = [...res.data.data.teacherList];
        vm.filterTeacherArr = vm.teacherList.map(item => item.userName);
        return this.$http.get(`${this.$API_PATH}/Admin/Course/Management`, {
          headers: {
            Accept: '*/*',
            Authorization: `bearer ${this.userAuth}`,
          },
        });
      }).then((res) => {
        this.$log.debug(res.data.data.courseList);
        this.updateLoading(false);
        this.data = res.data.data.courseList.map((item, i) => {
          const time = item.publishTime.split('T').join(' ');
          return {
            ...item,
            prevImageUrl: item.prevImageUrl == 'NotSet' ? 'https://imgur.com/uQFCjm6.png' : item.prevImageUrl,
            publishTime: time,
            authorId: vm.matchAuthor(item.authorId),
          };
        });
        this.rows = [...this.data];
        this.updateLoading(false);
      })
        .catch((err) => {
          this.updateLoading(false);
          if (err.response) {
            if (err.response.status == 401) {
              Swal.fire(
                '',
                '權限不足',
                'info',
              ).then((result) => {
                if (result.isConfirmed) {
                  this.$router.push('/');
                }
              });
            }
          } else {
            Swal.fire(
              '錯誤',
              '請稍候再試',
              'info',
            ).then((result) => {
              if (result.isConfirmed) {
                this.$router.push('/');
              }
            });
          }
        });
    },
    getData() {
      const vm = this;
      this.updateLoading(true);
      this.$http.get(`${this.$API_PATH}/Admin/Course/Management`, {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        this.$log.debug(res.data.data.courseList);
        this.updateLoading(false);
        this.data = res.data.data.courseList.map((item, i) => {
          const time = item.publishTime.split('T').join(' ');
          return {
            ...item,
            prevImageUrl: item.prevImageUrl == 'NotSet' ? 'https://imgur.com/uQFCjm6.png' : item.prevImageUrl,
            publishTime: time,
            authorId: vm.matchAuthor(item.authorId),
          };
        });
        this.rows = [...this.data];
      }).catch((err) => {
        this.updateLoading(false);
        if (err.response) {
          if (err.response.status == 401) {
            Swal.fire(
              '',
              '權限不足',
              'info',
            ).then((result) => {
              if (result.isConfirmed) {
                this.$router.push('/');
              }
            });
          }
        } else {
          Swal.fire(
            '錯誤',
            '請稍候再試',
            'info',
          ).then((result) => {
            if (result.isConfirmed) {
              this.$router.push('/');
            }
          });
        }
      });
    },
    switchPage(num) {
      this.page += num;
      if (this.page < 1) {
        this.page = 1;
      }
      if (this.noData) {

      }
      this.getData();
    },
    matchAuthor(id) {
      let result = '';
      this.teacherList.forEach((item) => {
        if (item.id == id) {
          result = item.userName;
        }
      });
      return result == '' ? '' : result;
    },
    handleModalRole(str) {
      this.createModalRole = str;
      this.fileUploading = '';
    },
    save() {
      // connect to api
      this.newUser = {
        userName: '',
        avatar: '',
        email: '',
        account: '',
        role: '',
      };
      this.$refs.img.src = '';
      this.newUser.avatar = '';
      this.$refs.files.value = null;
      this.$refs.img.src = '';
    },
    selectionChanged(data) {

    },
    openDeleteModal() {

    },
    goToCreate(role) {
      this.$router.push(`/admin/r/user/${role}`);
    },
    goCourse(id) {
      this.$router.push(`/admin/t/courses/${id}`);
    },
    onCellClick(param) {
      const vm = this;

      if (param.column.field == 'ena') {
        const query = {
          enable: !param.row.ena,
        };
        this.$http.put(`${this.$API_PATH}/Admin/Course/Management/Enable/${param.row.id}`, query, {
          headers: {
            Accept: '*/*',
            Authorization: `bearer ${this.userAuth}`,
            'Content-Type': 'application/json',
          },
        }).then((res) => {
          // console.log(res.data);
          this.getData();
        }).catch((err) => {
          this.updateLoading(false);
          if (err.response) {
            if (err.response.status == 401) {
              Swal.fire(
                '',
                '權限不足',
                'info',
              ).then((result) => {
                if (result.isConfirmed) {
                  this.$router.push('/');
                }
              });
            } else if (err.response.status == 404) {
              Swal.fire(
                '',
                '找不到此課程',
                'info',
              ).then((result) => {
                if (result.isConfirmed) {
                  this.$router.push('/');
                }
              });
            }
          } else {
            Swal.fire(
              '錯誤',
              '請稍候再試',
              'info',
            ).then((result) => {
              if (result.isConfirmed) {
                this.$router.push('/');
              }
            });
          }
        });
      } else if (param.column.field == 'btn') {
        if (param.event.target.innerText == '刪除') {
          Swal.fire({
            title: '確認刪除？',
            showCancelButton: true,
            confirmButtonText: '確認',
            cancelButtonText: '取消',
          }).then((result) => {
            if (result.isConfirmed) {
              vm.$http.delete(`${this.$API_PATH}/Admin/Course/Management/${param.row.id}`, {
                headers: {
                  Accept: '*/*',
                  Authorization: `bearer ${this.userAuth}`,
                },
              }).then((res) => {
                // console.log(res.data);
                this.getData();
              }).catch((err) => {
                this.updateLoading(false);
                if (err.response) {
                  if (err.response.status == 401) {
                    Swal.fire(
                      '',
                      '權限不足',
                      'info',
                    ).then((result) => {
                      if (result.isConfirmed) {
                        this.$router.push('/');
                      }
                    });
                  } else if (err.response.status == 404) {
                    Swal.fire(
                      '',
                      '找不到此課程',
                      'info',
                    ).then((result) => {
                      if (result.isConfirmed) {
                        this.$router.push('/');
                      }
                    });
                  }
                } else {
                  Swal.fire(
                    '錯誤',
                    '請稍候再試',
                    'info',
                  ).then((result) => {
                    if (result.isConfirmed) {
                      this.$router.push('/');
                    }
                  });
                }
              });
            }
          });
          
        } else if (param.event.target.innerText == '編輯課程') {
          this.$store.dispatch('courseModule/resetCourseData');
          this.$router.push(`/admin/edit_course/basic/${param.row.id}`);
        } else if (param.event.target.innerText == '學生管理') {
          this.$router.push(`/admin/t/courses/${param.row.id}`);
        }
      }
    },
    ...mapActions(['getUserAuth', 'getUserAuthFromLocal', 'checkExpAuth', 'updateLoading']),
  },
  computed: {
    ...mapGetters(['userName', 'Avatar', 'userId', 'role', 'exp', 'userAuth', 'LoginProvider']),
  },
  created() {
    this.getUserAuthFromLocal();
    this.checkExpAuth();
    // console.log(this.userAuth);
    if (this.userAuth.length == 0) {
      this.$router.push('/login');
    }
    this.getTeacherList();
    // this.getData();
  },
};
</script>

<style lang="scss" scoped>
  .pageNum {
    cursor: pointer;
    text-decoration: none;
    &.disabled {
      pointer-events: none;
      color: gray;
      cursor: default;
    }
  }
  ::v-deep #vgt-table tbody tr td:nth-of-type(1),
  ::v-deep #vgt-table tbody tr td:last-of-type,
  ::v-deep #vgt-table tbody tr td:nth-of-type(9) {
    text-align: center;
  }
  ::v-deep #vgt-table tbody tr td:last-of-type span {
    display: flex;
    flex-direction: column;
  }
  ::v-deep #vgt-table td span {
    line-height: 1.4rem;
  }

  ::v-deep #vgt-table thead th {
    min-width: 95px !important;
    padding-right: .75rem !important;
  }
</style>
