var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"my-5"},[_c('vue-good-table',{ref:"table",attrs:{"columns":_vm.columns,"rows":_vm.rows,"sort-options":{
        enabled: true,
        initialSortBy: {field: 'name', type: 'asc'}
      },"pagination-options":{
        enabled: true,
        mode: 'records',
        perPage: 10,
        perPageDropdownEnabled: true,
        perPageDropdown: [10, 20],
        nextLabel: '下一頁',
        prevLabel: '上一頁',
        rowsPerPageLabel: 'Rows per page',
        allLabel: '全部',
      }},on:{"on-cell-click":_vm.onCellClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'btn')?_c('span',[_c('button',{staticClass:"btn btn-main btn-sm mb-1",staticStyle:{"white-space":"nowrap"},attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();}}},[_vm._v("編輯課程")]),_c('button',{staticClass:"btn btn-main btn-sm mb-1",staticStyle:{"white-space":"nowrap"},attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();}}},[_vm._v("學生管理")]),_c('button',{staticClass:"btn btn-danger btn-sm",staticStyle:{"white-space":"nowrap"},attrs:{"type":"button"}},[_vm._v("刪除")])]):(props.column.field == 'prevImageUrl')?_c('span',[_c('img',{attrs:{"src":props.row.prevImageUrl,"alt":"","width":"180"}})]):(props.column.field == 'ena')?_c('span',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(props.row.ena),expression:"props.row.ena"}],attrs:{"type":"checkbox","name":"ena","id":"ena"},domProps:{"checked":Array.isArray(props.row.ena)?_vm._i(props.row.ena,null)>-1:(props.row.ena)},on:{"change":function($event){var $$a=props.row.ena,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(props.row, "ena", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(props.row, "ena", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(props.row, "ena", $$c)}}}})]):(props.column.field == 'name')?_c('span',[_c('span',[_vm._v(_vm._s(props.row.name))]),(!props.row.reviewed)?_c('span',[_c('br'),_c('span',{staticClass:"badge rounded-pill bg-info",staticStyle:{"padding":".15rem .6rem"}},[_vm._v("未審核")])]):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1),_c('Meta',{attrs:{"title":_vm.metaTitle}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }